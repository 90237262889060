import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

export const ToastProvider: FC = () =>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeButton={false}
        icon={false}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        stacked
    />;